



























import { defineComponent, toRefs } from '@vue/composition-api'
import useNews from '../../hooks/use-news'

export default defineComponent({
  name: 'ListContain',
  props: {
    news: {
      required: true
    },
    type: {
      required: true,
      default: 'news'
    }
  },
  setup() {
    const { state, handleMouse, handleSelect, parseTime, handleResolve } =
      useNews()

    return {
      ...toRefs(state),
      handleMouse,
      handleSelect,
      handleResolve,
      parseTime
    }
  }
})
